import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/fr';
import SEO from '../components/seo';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/terms/*.fr.md" } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            lead
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

const TermsMD = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout location={typeof window !== 'undefined' ? location : null}>
      <SEO
        title="Conditions Légales - Furlan Snowboards"
        description="Nos conditions légales"
        keywords={['snowboard', 'boards', 'polyvalence']}
        pathname="/fr/terms/"
      />
      <div className="termsPage">
        {posts.map(({ node: post }) => (
          <div key={post.id}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mt-5">
                  <h1 className="mt-5">{post.frontmatter.title}</h1>
                  <p>{post.frontmatter.lead}</p>
                </div>
              </div>
            </div>
            <div className="container mb-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    dangerouslySetInnerHTML={{ __html: post.html }}
                    className="content"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default TermsMD;
